import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Show,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    Edit,
    Create,
    DeleteButton,
    SimpleForm,
    BooleanInput,
    TextInput,
    SelectInput,
    NumberField,
    SimpleShowLayout,
    ArrayInput,
    SimpleFormIterator,
    useRecordContext,
    ArrayField,
    useSimpleFormIteratorItem,
    NumberInput,
    BooleanField,
    Labeled,
    FunctionField,
    ReferenceInput,
    useDataProvider,
    AutocompleteInput,
    useNotify,
    Button,
} from 'react-admin';
import { Box, Divider, Grid } from '@mui/material';
import { useWatch, useFormContext } from 'react-hook-form';
import ExpandMore from "@mui/icons-material/ExpandMore";

const filters = [
    <TextInput label="Robot ID" source="robotId" alwaysOn />,
];
const ioControlTypeFields = {
    "Bool": ["ioPin", "onVal", "offVal", "reverseVal", "cancelOnPause", "cancelOnStop", "legacyId"],
    "BoolReverse": ["ioPin", "onVal", "offVal", "cancelOnPause", "cancelOnStop", "legacyId"],
    "Point": ["ioPin", "onMsg", "offMsg", "cancelOnPause", "cancelOnStop", "legacyId"],
    "PointReverse": ["ioPin", "onMsg", "offMsg", "cancelOnPause", "cancelOnStop", "legacyId"],
    "Weight": ["motorCurrentThreshold"],
    "Timed": ["ioPin", "onVal", "offVal", "timeTolerance"],
    "Passthrough": [],
    "Gripper": ["ioPin", "isReversed", "openDelay", "closeDelay", "feedbackTopic", "feedbackIos", "enableIosFeedback",
        "triggerPlanningSceneUpdate", "planningSceneName", "enableFreedriveAtClose"]
}
const basicTypes = [
    "Bool", "Point", "Passthrough", "Gripper"
]
const advancedTypes = {
    "Bool": ["BoolReverse", "Weight", "Timed"],
    "Point": ["PointReverse", "Weight", "Timed"],
    "Passthrough": [],
    "Gripper": []
}
const topicsByType = {
    "Bool": ["arduino_controller", "arduino_syrup"],
    "BoolReverse": ["arduino_controller", "arduino_syrup"],
    "Point": ["arduino_controller", "grinder_servo", "/cam0/arducam/camera/flash"],
    "PointReverse": ["arduino_controller",],
    "Weight": ["arduino_weight", "arduino_weight_syrup", "arduino_weight_ice"],
    "Timed": ["arduino_gs3_timed",],
    "Passthrough": [],
    "Gripper": ["/ur_driver/set_digital_io"]
}
const feedbackTopics = [
    "/ur_hardware_interface/set_io"
];
const planningSceneNames = [
    "O_Gripper",
    "Tri_Gripper"
];
const getIoControlTypeChoices = (index, selectedBasicType) => {
    if (index === 0) {
        return basicTypes.map(type => ({ id: type, name: type }))
    } else {
        if (!selectedBasicType || !(selectedBasicType in advancedTypes)) {
            return [];
        }
        return advancedTypes[selectedBasicType].map(type => {
            if (type === "BoolReverse") {
                return { id: type, name: "Reverse" }
            } else if (type === "PointReverse") {
                return { id: type, name: "Reverse" }
            }
            return { id: type, name: type }
        });
    }
}
const getIoControlTopicChoices = (selectedType) => {
    return topicsByType[selectedType]?.map(topic => ({ id: topic, name: topic }));
}

const IoControlInput = (props) => {
    const { index, total } = useSimpleFormIteratorItem();
    const [selectedType, setSelectedType] = useState("");
    const handleTypeChange = (index, value) => {
        setSelectedType(value);
    };
    const { getValues } = useFormContext();
    const record = getValues();
    useEffect(() => {
        // Set selectedType once ioControls[index].type is loaded
        const ioControlType = record?.ioControls[index]?.type;
        if (ioControlType) {
            handleTypeChange(index, ioControlType);
        }
    }, [record, index]);
    const ioPinRequired = ['Bool', 'BoolReverse', 'Point', 'PointReverse', 'Gripper'].includes(selectedType);
    const onValAndOffValRequired = ['Bool', 'BoolReverse'].includes(selectedType);
    const isBaseControlDisabled = index === 0 && total > 1;
    return (<Box display="flex" gap={1} flexWrap="wrap" width="100%">
        <SelectInput
            source={`ioControls[${index}].type`}
            label="Type"
            choices={getIoControlTypeChoices(index, record?.ioControls[0].type) ?? []}
            onChange={(event) => handleTypeChange(index, event.target.value)}
            required
            inputProps={{
                readOnly: isBaseControlDisabled,
            }}
            sx={{ flex: 1, minWidth: '100%' }}
        />
        {(selectedType != "" && selectedType != "Passthrough") && <SelectInput source={`ioControls[${index}].topic`}
            label="Topic"
            choices={getIoControlTopicChoices(selectedType) ?? []}
            defaultValue={index != 0 ? record?.ioControls[0].topic : ""}
            required
            sx={{ flex: 1, minWidth: '10%' }}
        />}
        {ioControlTypeFields[selectedType]?.includes("ioPin") &&
            <NumberInput source={`ioControls[${index}].ioPin`} label="IO Pin" required={ioPinRequired} sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("onVal") &&
            <NumberInput source={`ioControls[${index}].onVal`} label="On Val" required={onValAndOffValRequired} sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("offVal") &&
            <NumberInput source={`ioControls[${index}].offVal`} label="Off Val" required={onValAndOffValRequired} sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("reverseVal") &&
            <NumberInput source={`ioControls[${index}].reverseVal`} label="Reverse Val" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("motorCurrentThreshold") &&
            <NumberInput source={`ioControls[${index}].motorCurrentThreshold`} label="Motor Current Threshold" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("onMsg") &&
            <div style={{ display: 'flex' }} sx={{ flex: 1, minWidth: '10%' }}>
                <NumberInput source={`ioControls[${index}].onMsg.x`} label="On Msg X" required />
                <NumberInput source={`ioControls[${index}].onMsg.y`} label="On Msg Y" required />
                <NumberInput source={`ioControls[${index}].onMsg.z`} label="On Msg Z" required />
            </div>}
        {ioControlTypeFields[selectedType]?.includes("offMsg") &&
            <div style={{ display: 'flex' }} sx={{ flex: 1, minWidth: '10%' }}>
                <NumberInput source={`ioControls[${index}].offMsg.x`} label="Off Msg X" required />
                <NumberInput source={`ioControls[${index}].offMsg.y`} label="Off Msg Y" required />
                <NumberInput source={`ioControls[${index}].offMsg.z`} label="Off Msg Z" required />
            </div>}
        {ioControlTypeFields[selectedType]?.includes("timeTolerance") &&
            <NumberInput source={`ioControls[${index}].timeTolerance`} label="Time Tolerance" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("cancelOnPause") &&
            <BooleanInput source={`ioControls[${index}].cancelOnPause`} label="Cancel on Pause" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("cancelOnStop") &&
            <BooleanInput source={`ioControls[${index}].cancelOnStop`} label="Cancel on Stop" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("legacyId") &&
            <TextInput source={`ioControls[${index}].legacyId`} label="Legacy ID" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("isReversed") &&
            <BooleanInput source={`ioControls[${index}].isReversed`} label="Is Reversed?" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("openDelay") &&
            <NumberInput source={`ioControls[${index}].openDelay`} label="Open Delay" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("closeDelay") &&
            <NumberInput source={`ioControls[${index}].closeDelay`} label="Close Delay" sx={{ flex: 1, minWidth: '10%' }} />}   
        {ioControlTypeFields[selectedType]?.includes("planningSceneName") &&
            <SelectInput source={`ioControls[${index}].planningSceneName`} label="Planning Scene Name" sx={{ flex: 1, minWidth: '10%' }}
            choices={planningSceneNames.map(sceneName => ({ id: sceneName, name: sceneName }))} />}
        {ioControlTypeFields[selectedType]?.includes("feedbackTopic") &&
            <SelectInput source={`ioControls[${index}].feedbackTopic`} label="Feedback Topic" sx={{ flex: 1, minWidth: '10%' }}
            choices={feedbackTopics.map(topic => ({ id: topic, name: topic }))} />}
        {ioControlTypeFields[selectedType]?.includes("enableFreedriveAtClose") &&
            <BooleanInput source={`ioControls[${index}].enableFreedriveAtClose`} 
            label="Enable Freedrive At Close" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("triggerPlanningSceneUpdate") &&
            <BooleanInput source={`ioControls[${index}].triggerPlanningSceneUpdate`} 
            label="Trigger Planning Scene Update" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("enableIosFeedback") &&
            <BooleanInput source={`ioControls[${index}].enableIosFeedback`} label="Enable IOs Feedback" sx={{ flex: 1, minWidth: '10%' }} />}
        {ioControlTypeFields[selectedType]?.includes("feedbackIos") &&
            <ArrayInput source={`ioControls[${index}].feedbackIos`} label="Feedback IOs" sx={{ flex: 1, minWidth: '100%' }} >
                <SimpleFormIterator >
                    <NumberInput />
                </SimpleFormIterator>
            </ArrayInput>}
    </Box>);
}


export const RobotIOControlDataList = props => (
    <List {...props} sort={{ field: 'ioControlKey', order: 'ASC' }}
        filters={filters} filterDefaultValues={{ robotId: 0 }} pagination={false}>
        <Datagrid>
            <TextField source="robotId" />
            <TextField source="ioControlKey" />
            <TextField source="name" />
            <ArrayField source="ioControls">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="type" label="Type" />
                    <TextField source="topic" label="Topic" />
                    <TextField source="ioPin" label="IO Pin" />
                    <NumberField source="legacyId" label="Legacy ID" />
                </Datagrid>
            </ArrayField>
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

const GripperDatagrid = () => {
    const [showGripperColumns, setShowGripperColumns] = useState(false);
    const toggleColumns = () => {
        setShowGripperColumns(!showGripperColumns);
    };
    const filterGripperControls = (ioControls) =>
        ioControls.filter(control => control.type === 'Gripper');
    const record = useRecordContext();
    if (!record) return null;
    const gripperControls = record.ioControls.filter(control => control.type === 'Gripper');

    return gripperControls.length > 0 ? (<>
    <Button onClick={toggleColumns} label={showGripperColumns ? "Hide Gripper Details" : "Show Gripper Details"}>
    <ExpandMore />
    </Button>
    {showGripperColumns && (
        <ArrayField source="ioControls">
            <Datagrid bulkActionButtons={false} data={filterGripperControls(record.ioControls)}> 
                <TextField source="type" label="Type" />
                <BooleanField source="isReversed" label="Is Reversed?" />
                <NumberField source="openDelay" label="Open Delay" />
                <NumberField source="closeDelay" label="Close Delay" />
                <TextField source="feedbackTopic" label="Feedback Topic" />
                <FunctionField
                    label="Feedback IOs"
                    render={record => record.feedbackIos?.join(', ')}
                />
                <BooleanField source="enableIosFeedback" label="Enable IOs Feedback" />
                <BooleanField source="triggerPlanningSceneUpdate" label="Trigger Planning Scene Update" />
                <TextField source="planningSceneName" label="Planning Scene Name" />
                <BooleanField source="enableFreedriveAtClose" label="Enable Freedrive At Close" />
            </Datagrid>
        </ArrayField>
    )}
    </>) : null;
}

export const RobotIOControlDataShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="robotId" />
            <TextField source="ioControlKey" />
            <TextField source="name" />
            <Divider>IO Controls</Divider>
            <ArrayField source="ioControls">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="type" label="Type" />
                    <TextField source="topic" label="Topic" />
                    <TextField source="ioPin" label="IO Pin" />
                    <NumberField source="legacyId" label="Legacy ID" />
                    <NumberField source="onVal" label="On Value" />
                    <NumberField source="offVal" label="Off Value" />
                    <FunctionField source="onMsg" label="On Msg" render={record => {
                        if (!record.onMsg) {
                            return null;
                        }
                        return `x: ${record.onMsg?.x ?? 'null'}, 
                        y: ${record.onMsg?.y ?? 'null'}, 
                        z: ${record.onMsg?.z ?? 'null'}`;
                    }} />
                    <FunctionField source="offMsg" label="Off Msg" render={record => {
                        if (!record.offMsg) {
                            return null;
                        }
                        return `x: ${record.offMsg?.x ?? 'null'}, 
                        y: ${record.offMsg?.y ?? 'null'}, 
                        z: ${record.offMsg?.z ?? 'null'}`;
                    }} />
                    <NumberField source="reverseVal" label="Reverse Value" />
                    <NumberField source="motorCurrentThreshold" label="Motor Current Threshold" />
                    <NumberField source="timeTolerance" label="Time Tolerance" />
                    <BooleanField source="cancelOnPause" label="Cancel on Pause" />
                    <BooleanField source="cancelOnStop" label="Cancel on Stop" />
                </Datagrid>
            </ArrayField>
            <GripperDatagrid />
        </SimpleShowLayout>
    </Show>
);

const IOControlDataContext = React.createContext([]);

function IOControlsInput({ useCommonData }) {
    const [choices, setChoices] = useState([]);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const record = useRecordContext();
    useEffect(() => {
        const resource = useCommonData ? `robot_common_ios_control_data` : `robot_ios_control_data`;
        const params = useCommonData || record?.robotId === undefined ? {} : { filter: { robotId: record.robotId } };
        dataProvider.getList(resource, params)
            .then(({ data }) => {
                setChoices(data.sort((a, b) => a.ioControlKey.localeCompare(b.ioControlKey)));
            })
            .catch((error) => {
                notify(error.message);
            });
    }, [dataProvider]);

    return (
        <>
            <AutocompleteInput source="ioControlKey" optionText="ioControlKey" optionValue="ioControlKey" choices={choices} fullWidth />
            <IOControlDataContext.Provider value={choices}>
                <IOControlDataFields />
            </IOControlDataContext.Provider>
        </>
    );
}

function IOControlDataFields() {
    const ioControlKey = useWatch({ name: 'ioControlKey' });
    const { setValue } = useFormContext();
    const ioControlKeyOptions = React.useContext(IOControlDataContext);
    useEffect(() => {
        const ioControlKeyOption = ioControlKeyOptions.find(option => option.ioControlKey === ioControlKey);
        if (ioControlKeyOption == null) {
            return;
        }
        setValue('name', ioControlKeyOption.name);
        setValue('ioControls', ioControlKeyOption.ioControls);
    }, [ioControlKeyOptions, ioControlKey]);
    return ioControlKey ? (
        <>
            <TextInput source="name" required fullWidth />
            <Divider>IO Controls</Divider>
            <ArrayInput source="ioControls" fullWidth>
                <SimpleFormIterator TransitionProps={{ enter: false, exit: false }} reOrderButtons={<></>} fullWidth inline>
                    <IoControlInput />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    ) : null;
}

export const RobotIOControlDataCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="robotId" reference="robots" required />
                <IOControlsInput useCommonData={true} />
            </SimpleForm>
        </Create>
    );
};

export const RobotIOControlDataEdit = props => {
    return (<Edit {...props}>
        <SimpleForm>
            <Grid>
                <Labeled label="Robot ID">
                    <TextField source="robotId" />
                </Labeled>
            </Grid>
            <Grid>
                <Labeled label="IO control key">
                    <TextField source="ioControlKey" required />
                </Labeled>
            </Grid>
            <IOControlsInput useCommonData={false} />
        </SimpleForm>
    </Edit>);
};