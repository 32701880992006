import React, { useMemo, useState } from 'react';
import {
    List,
    Show,
    Datagrid,
    BooleanField,
    TextField,
    ReferenceField,
    ReferenceArrayField,
    DeleteButton,
    EditButton,
    ShowButton,
    BulkUpdateButton,
    Edit,
    Create,
    useCreateController,
    SimpleForm,
    BooleanInput,
    NullableBooleanInput,
    TextInput,
    ArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    FormDataConsumer,
    SimpleShowLayout,
    useRecordContext,
    CloneButton,
    SaveButton,
    Toolbar,
    required,
    Labeled,
    useListContext,
    BulkUpdateWithConfirmButton,
    DateField,
    FunctionField,
} from 'react-admin';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useWatch } from 'react-hook-form';
import { Alert } from '@mui/material';

import { BluehillPagination } from './bluehillPagination';

import SortableIterator from './components/SortableIterator';
import AutocompleteInput from './components/BaseAutocompleteInput';
import DisabledReasonInput from './components/DisabledReasonInput';
import { PostSubmitPopUp } from './components/PostSubmitPopUp';
import { ExecuteOperationButton } from './components/ExecuteOperationButton';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { SYNC_CLOUD_DATA_OPERATION_ID } from './robotOperationConstants';

const filters = [
    <TextInput label="Configuration" source="configuration" alwaysOn />,
    <TextInput label="Program name" source="partialName" alwaysOn />,
    <NullableBooleanInput label="Is product?" source="isProduct" nullLabel="All" falseLabel="Program" trueLabel="Product" alwaysOn />,
    <BooleanInput label="Enabled" source="enabled" />,
];

const robotRenderer = robot => `${robot?.configuration}(R${robot?.robotId})`;
const reasonRenderer = (reasonStatusInformation) => {
    const reasonTitle = reasonStatusInformation?.reasonTitle;
    const reasonDescription = reasonStatusInformation?.reasonDescription;
    return reasonTitle ? (reasonDescription ? `${reasonTitle}: ${reasonDescription}` : reasonTitle) : '';
}

const DisabledReasonField = ({ label }) => {
    const record = useRecordContext();
    if (!record) return null;
    return <FunctionField label={label}
        render={record => reasonRenderer(record?.resourceStatusInformation)} />;
}

const BulkEnableButton = ({ robotId, ...props }) => {
    const [dialogState, setDialogState] = useState({ popup: false, robotId: null });
    return (<><BulkUpdateButton
        {...props}
        label="Enable"
        data={{ enabled: true }}
        icon={<Visibility />}
        mutationMode="optimistic"
        onClick={() => setDialogState({ popup: true, robotId: robotId })}
    />
    <PostSubmitPopUp dialogState={dialogState} setDialogState={setDialogState} dialogTitle="Sync Cloud Data?" 
        dialogTextContent="Robot program successfully enabled.">
        <ExecuteOperationButton operationId={SYNC_CLOUD_DATA_OPERATION_ID} label="SYNC CLOUD DATA" startIcon={<CloudSyncIcon />}
            operationName="Sync Cloud Data" robotId={dialogState.robotId} disabled={false} />
    </PostSubmitPopUp>
    </>);
};

const BulkDisableButton = ({ robotId, ...props }) => {
    const [reasonType, setReasonType] = useState('OUT_OF_STOCK');
    const [reasonDescription, setReasonDescription] = useState('');
    const [dialogState, setDialogState] = useState({ popup: false, robotId: null });
    return (<><BulkUpdateWithConfirmButton
        {...props}
        label="Disable"
        data={{ 
            enabled: false, 
            resourceStatusInformation: {
                reasonType: reasonType,
                reasonDescription: reasonDescription
            }
        }}
        icon={<VisibilityOff />}
        confirmContent={
            <DisabledReasonInput
                reasonType={reasonType}
                setReasonType={setReasonType}
                reasonDescription={reasonDescription}
                setReasonDescription={setReasonDescription}
            />
        }
        onClick={() => setDialogState(prev => ({ ...prev, robotId: robotId, popup: true }))}
    />
    <PostSubmitPopUp dialogState={dialogState} setDialogState={setDialogState} dialogTitle="Sync Cloud Data?" 
        dialogTextContent="Robot program successfully disabled.">
        <ExecuteOperationButton operationId={SYNC_CLOUD_DATA_OPERATION_ID} label="SYNC CLOUD DATA" startIcon={<CloudSyncIcon />}
            operationName="Sync Cloud Data" robotId={dialogState.robotId} disabled={false} />
    </PostSubmitPopUp>
    </>);
}

const BulkActionButtons = props => {
    const robotId = props?.filterValues?.configuration;
    return (<>
        <BulkEnableButton robotId={robotId} />
        <BulkDisableButton robotId={robotId} />
    </>);
};

const CloneRowButton = () => {
    const rowRecord = useRecordContext();
    return (
        <CloneButton
            record={{ ...rowRecord, configuration: rowRecord?.configuration }}
        />
    );
}

export const ProgramList = props => (
    <List {...props} 
        perPage={50} sort={{ field: '', order: '' }}
        filters={filters} filterDefaultValues={{ configuration: 0 }} pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={<BulkActionButtons />}>
            <TextField source="programId" />
            <TextField source="configuration" />
            <TextField source="name" style={{ wordBreak: 'break-word' }} />
            <TextField source="programKey" style={{ wordBreak: 'break-word' }} />
            <TextField source="productId" />
            <BooleanField source="enabled" />
            <DisabledReasonField label="Disabled reason" />
            <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
            <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
            <ShowButton />
            <EditButton />
            <CloneRowButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

const taskOptionRenderer = task => {
    if (!task) {
        return '';
    } else {
        return task.id + ": " + task.name;
    }
};


const ListContent = (props) => {
    const { source } = props;
    const { data } = useListContext();
    const record = useRecordContext();

    const dataArray = useMemo(() => {
        const ids = record[source] || [];
        if (data?.length && ids?.length) {
            const tempMap = {};
            data.forEach(e => tempMap[e?.id] = e);
            return ids.map((e, index) => tempMap[e]);
        }
        return [];
    }, [record, data, source]);

    return (
        <Datagrid bulkActionButtons={false} {...props} data={dataArray}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="taskKey" />
            <ShowButton label="Show" />
            <EditButton label="Adjust Actions" resource='/robot_task_operation_executions' />
        </Datagrid>
    );
}

export const ProgramShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="programId" />
                <TextField source="productId" />
                <ReferenceField label="Product Name" source="productId" reference="product_metadata" alwaysOn>
                    <TextField source="productName" />
                </ReferenceField>
                <TextField source="configuration" />
                <TextField source="name" />
                <TextField source="programKey" />
                <BooleanField source="enabled" />
                <DisabledReasonField label="Disabled reason" />
                <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
                <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
                <ReferenceField source="itemDoneTaskId" reference="robot_tasks">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceArrayField label="Tasks" reference="robot_tasks" source="taskIds" >
                    <ListContent source="taskIds" />
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export const ProgramEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Labeled label="Program Id">
                <TextField source="programId" />
            </Labeled>
            <Labeled label="Configuration">
                <TextField source="configuration" />
            </Labeled>
            <ReferenceInput label="Product Name" source="productId" reference="product_metadata" perPage={50}>
                <AutocompleteInput optionText="productName"
                    queryFields={['productName']}
                    filterToQuery={(productName) => ({ productName })}
                    shouldRenderSuggestions={searchText => searchText.trim().length > 1} />
            </ReferenceInput>
            <BooleanInput source="enabled" />
            <TextInput source="name" validate={[required()]} />
            <ReferenceInput source="programKey" reference="robot_program_keys" fullWidth>
                <AutocompleteInput optionText="programKey"
                    queryFields={['programKey']}
                    filterToQuery={searchText => ({ partialProgramKey: searchText })}
                    shouldRenderSuggestions={searchText => searchText.trim().length > 1}
                    validate={[required()]}
                />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return (
                        <>
                            <ReferenceInput source="itemDoneTaskId" reference="robot_tasks">
                                <AutocompleteInput
                                    optionText={taskOptionRenderer}
                                    fullWidth
                                    filterToQuery={searchText => ({ partialName: searchText, configuration: formData.configuration })}
                                    shouldRenderSuggestions={searchText => searchText.trim().length >= 1}
                                />
                            </ReferenceInput>
                            <ArrayInput source="taskIds" allowduplicates="true" >
                                <SortableIterator filterObj={{
                                    configuration: formData.configuration
                                }}>
                                    <ReferenceInput reference="robot_tasks">
                                        <AutocompleteInput
                                            optionText={taskOptionRenderer}
                                            fullWidth
                                            filterToQuery={searchText => ({ partialName: searchText, configuration: formData.configuration })} 
                                            shouldRenderSuggestions={searchText => searchText.trim().length >= 1} />
                                    </ReferenceInput>
                                </SortableIterator>
                            </ArrayInput>
                        </>
                    );
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

const CustomToolbar = (props) => {
    const record = useRecordContext();
    const programName = useWatch({ name: 'name' });
    const programKey = useWatch({ name: 'programKey' });
    const invalid = !programName || programName === record?.name || !programKey || programKey === record?.programKey;
    return (<Toolbar {...props}>
        <SaveButton
            {...props}
            disabled={invalid}
        />
        {record && invalid && <Alert severity="warning" style={{ marginLeft: '.5em' }}>The program name and program key should not be empty or same as before</Alert>}
    </Toolbar>)
};

export const ProgramCreate = props => {
    const { record } = useCreateController(props);
    return (<Create {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <ReferenceInput sort={{ field: '', order: 'ASC' }}
                source="configuration" reference="robots" >
                <AutocompleteInput label="Configuration" optionText={robotRenderer} queryFields={['configuration', 'id']} disabled={record?.configuration !== undefined}/>
            </ReferenceInput>
            <ReferenceInput label="Product Name" source="productId" reference="product_metadata" perPage={50}>
                <AutocompleteInput optionText="productName"
                    queryFields={['productName']}
                    filterToQuery={(productName) => ({ productName })}
                    shouldRenderSuggestions={searchText => searchText.trim().length > 1} />
            </ReferenceInput>
            <BooleanInput source="enabled" />
            <TextInput source="name" validate={[required()]} />
            <ReferenceInput source="programKey" reference="robot_program_keys">
                <AutocompleteInput
                    optionText="programKey"
                    queryFields={['programKey']}
                    filterToQuery={searchText => ({ partialProgramKey: searchText })}
                    fullWidth
                    shouldRenderSuggestions={searchText => searchText.trim().length >= 0}
                    validate={[required()]}
                />
            </ReferenceInput>
            <FormDataConsumer >
                {({ formData, ...rest }) => {
                    return (
                        <>
                            <ReferenceInput source="itemDoneTaskId" reference="robot_tasks" allowEmpty={true}>
                                <AutocompleteInput
                                    optionText={taskOptionRenderer}
                                    fullWidth
                                    filterToQuery={searchText => ({ partialName: searchText, configuration: formData.configuration })} 
                                    shouldRenderSuggestions={searchText => searchText.trim().length >= 1} />
                            </ReferenceInput>
                            <ArrayInput source="taskIds" allowduplicates="true" >
                                <SimpleFormIterator TransitionProps={{ enter: false, exit: false }} fullWidth getItemLabel={index => `${index + 1}`}>
                                    <ReferenceInput reference="robot_tasks">
                                        <AutocompleteInput optionText={taskOptionRenderer}
                                            fullWidth
                                            filterToQuery={searchText => ({ partialName: searchText, configuration: formData.configuration })}
                                            shouldRenderSuggestions={searchText => searchText.trim().length >= 1} />
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </>
                    );
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
)};