export const bluehillApiDomain = () => {
    if (process.env.REACT_APP_STAGE === 'local') {
        return 'http://localhost:5200';
    } else if (process.env.REACT_APP_STAGE === 'prod') {
        return 'https://api.bluehill.coffee';
    } else if (process.env.REACT_APP_STAGE === 'gamma') {
        return 'https://api-gamma.bluehill.coffee';
    } else if (process.env.REACT_APP_STAGE === 'beta') {
        return 'https://api-beta.bluehill.coffee';
    } else {
        return 'https://api-beta.bluehill.coffee';
    }
};
export const bluehillKeyCloakDomain = () => {
    if (process.env.REACT_APP_STAGE === 'prod'
        || process.env.REACT_APP_STAGE === 'gamma') {
        return 'https://api.bluehill.coffee';
    } else if (process.env.REACT_APP_STAGE === 'beta') {
        return 'https://api-beta.bluehill.coffee';
    } else {
        return 'https://api-beta.bluehill.coffee';
    }
};

export const keycloakRealm = () => {
    return process.env.REACT_APP_STAGE === 'prod'
        || process.env.REACT_APP_STAGE === 'gamma'
        ? 'bluehill-prod' : 'bluehill-beta';
};
