import React, { useCallback, useMemo, useState } from 'react';
import { Layout, AppBar, LocalesMenuButton, LoadingIndicator } from 'react-admin';

import { makeStyles } from '@mui/styles';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BuildIcon from '@mui/icons-material/Build';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { blue } from '@mui/material/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BluehillMenu } from './menu';

const useStyles = makeStyles(
    theme => ({
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        status: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #fff',
            padding: '5px',
            fontSize: '80%',
            marginRight: '.5rem',
        },
        statusIcon: {
            fontSize: '1rem',
            marginRight: '.5rem',
        }
    }),
    { name: 'RaAppBar' }
);


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: process.env.REACT_APP_STAGE === 'prod'
        || process.env.REACT_APP_STAGE === 'gamma' ? '#e5c33f' : blue[500],
    '&:hover': {
      backgroundColor: process.env.REACT_APP_STAGE === 'prod'
        || process.env.REACT_APP_STAGE === 'gamma' ? '#e5c33f' : blue[700],
    },
    borderColor: '#fff',
  }));

const DropDown = React.memo(({ statusText, onClick, buttonText }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <ColorButton
          id="basic-button"
          variant="outlined"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
        {statusText}
        </ColorButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={onClick}>{buttonText}</MenuItem>
        </Menu>
      </div>
    );
});

const SwitchDomainButton = React.memo(({ onClick, buttonText }) => {
    return (
        <ColorButton variant="text" size="small" onClick={onClick}>
            {buttonText}
        </ColorButton>
    );
});

const Toolbar = (props) => {
    const classes = useStyles(props);
    const versionCode = process.env.REACT_APP_VERSION;
    const { hash } = window.location;
    const statusText = useMemo(() => {
        return `${(process.env.REACT_APP_STAGE || 'dev').toUpperCase()}-${/v3/i.test(process.env.REACT_APP_VERSION)
            ? 'LEGACY' : 'NEW'}`;
    }, []);

    const isLargeEnough = useMediaQuery(theme =>
        theme.breakpoints.up('sm')
    );

    const switchDomain = useCallback(() => {
        let domain = '';
        if (process.env.REACT_APP_STAGE === 'prod') {
            domain = /v3/i.test(versionCode) ? process.env.REACT_APP_PROD_DOMAIN_V4 : process.env.REACT_APP_PROD_DOMAIN_V3;
        } else {
            domain = /v3/i.test(versionCode) ? process.env.REACT_APP_BETA_DOMAIN_V4 : process.env.REACT_APP_BETA_DOMAIN_V3;
        }
        domain = domain + hash;
        if (domain) {
            window.location.assign(domain);
        }
    }, [hash, versionCode]);

    const buttonText = useMemo(() => {
        return /v3/i.test(versionCode) ? 'Back To New UI' : 'Switch To Legacy UI';
    }, [versionCode])

    return (
        <>
            {isLargeEnough
                ? <>
                    <span className={classes.status}>
                        {process.env.REACT_APP_STAGE === 'prod'
                            ? <BeenhereIcon className={classes.statusIcon} />
                            : <BuildIcon className={classes.statusIcon} />
                        }
                        {statusText}
                    </span>
                    <SwitchDomainButton buttonText={buttonText} onClick={switchDomain} />
                </>
                : <DropDown statusText={statusText} buttonText={buttonText} onClick={switchDomain}/>
            }
            <LoadingIndicator />
        </>
    )
}

function TopBar(props) {

    return (
        <AppBar
            {...props}
            style={{
                backgroundColor: process.env.REACT_APP_STAGE === 'prod'
                    || process.env.REACT_APP_STAGE === 'gamma' ? '#e5c33f' : '#2196f3',
            }}
            toolbar={<Toolbar />}
        />
    );
}

export const BluehillLayout = (props) => {
    return (
        <Layout {...props} menu={BluehillMenu} appBar={TopBar} />
    );
};
