import React, { useState, useEffect, useCallback } from 'react';
import {
    List,
    Show,
    Datagrid,
    BooleanField,
    TextField,
    ReferenceField,
    ReferenceArrayField,
    DeleteButton,
    EditButton,
    ShowButton,
    BulkUpdateButton,
    Edit,
    Create,
    useCreateController,
    SimpleForm,
    BooleanInput,
    NullableBooleanInput,
    TextInput,
    ArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    FormDataConsumer,
    SimpleShowLayout,
    TabbedShowLayout,
    useRecordContext,
    CloneButton,
    SaveButton,
    Toolbar,
    required,
    Labeled,
    useListContext,
    DeleteWithConfirmButton,
    DateField,
    FunctionField,
    useShowController,
    useEditController,
    TabbedForm,
    useInput,
    useDataProvider,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { JsonInput } from "react-admin-json-view";
import { JsonEditorInput } from "./components/JsonEditorInput.js";

import { BluehillPagination } from './bluehillPagination';

import AutocompleteInput from './components/BaseAutocompleteInput';
import FlowCanvas from './components/FlowCanvas';
import { ValueProvider } from './components/ValueContext';

const filters = [
    <TextInput label="Robot group" source="robotGroupKey" alwaysOn />,
    <TextInput label="Program key" source="partialProgramKey" alwaysOn />,
];

export const RobotGroupProgramList = props => (
    <List {...props} 
        perPage={50} sort={{ field: '', order: '' }}
        filters={filters}
        filterDefaultValues={{ robotGroupKey: 'ALL' }}
        pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="robotGroupKey" />
            <TextField source="programKey" style={{ wordBreak: 'break-word' }} />
            <DateField source="createdTime" showTime />
            <DateField source="updatedTime" showTime />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

function StateMachineDataProvider(props) {
    const [robotConditions, setRobotConditions] = useState([]);
    // const [stateClasses, setStateClasses] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getList('robot_conditions', {}).then(({ data }) => {
            if (data?.length) {
                setRobotConditions(data);
            }
        }).catch((err) => {
            console.error(err);
        });

        // dataProvider.getList('robot_state_classes', {}).then(({ data }) => {
        //     if (data?.length) {
        //         setStateClasses(data);
        //     }
        // }).catch((err) => {
        //     console.error(err);
        // });
    }, [dataProvider]);

    if (!robotConditions.length) {
        return null;
    }

    return (
        <ValueProvider value={{ robotConditions }}>
            {props.children}
        </ValueProvider>
    )
}

function StateMachineField({ source, disabled }) {
    const record = useRecordContext();
    const { field } = useInput({ source });
    const form = useFormContext();
    const onValueChange = useCallback((value) => {
        form.setValue(source, value, { shouldDirty: true });
    }, [form, source]);

    if (!record) {
        return null;
    }

    return (
        <Stack direction="column" sx={{ width: '100%', minWidth: '600px', height: '900px' }}>
            <StateMachineDataProvider>
                <FlowCanvas value={field.value} onChange={onValueChange} disabled={disabled} />
            </StateMachineDataProvider>
        </Stack>
    );
}

export const RobotGroupProgramShow = props => {
    const { record } = useShowController(props);
    return (
        <Show {...props}>
            <TabbedShowLayout sx={{ display: 'flex', flexDirection: 'column' }}>
                <TabbedShowLayout.Tab label="General">
                    <TextField source="id" />
                    <TextField source="robotGroupKey" />
                    <TextField source="programKey" />
                    <DateField source="createdTime" showTime />
                    <DateField source="updatedTime" showTime />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="State machine" path="state-machine">
                    <Stack direction="column" sx={{ width: '100%', minWidth: '600px', height: '900px' }}>
                        <StateMachineDataProvider>
                            <FlowCanvas value={record?.stateMachineData} disabled />
                        </StateMachineDataProvider>
                    </Stack>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const RobotGroupProgramCreate = props => {
    return (
        <Create {...props}>
            <TabbedForm>
                <TabbedForm.Tab label="General">
                    <TextInput source="robotGroupKey" />
                    <ReferenceInput source="programKey" reference="robot_program_keys" fullWidth>
                        <AutocompleteInput optionText="programKey"
                            queryFields={['programKey']}
                            filterToQuery={searchText => ({ partialProgramKey: searchText })}
                            shouldRenderSuggestions={searchText => searchText.trim().length > 1} />
                    </ReferenceInput>
                </TabbedForm.Tab>
            </TabbedForm>
        </Create>
    );
};

export const RobotGroupProgramEdit = props => {
    return (
        <Edit {...props}>
            <TabbedForm
                toolbar={(
                    <Toolbar sx={{
                        justifyContent: 'space-between',
                    }}>
                        <SaveButton />
                        <DeleteWithConfirmButton
                            confirmContent="Are you sure you want to delete this record?"
                        />
                    </Toolbar>
                )}
            >
                <TabbedForm.Tab label="General">
                    <Labeled>
                        <TextField source="id" />
                    </Labeled>
                    <Labeled>
                        <TextField source="robotGroupKey" />
                    </Labeled>
                    <ReferenceInput source="programKey" reference="robot_program_keys" fullWidth>
                        <AutocompleteInput optionText="programKey"
                            queryFields={['programKey']}
                            filterToQuery={searchText => ({ partialProgramKey: searchText })}
                            shouldRenderSuggestions={searchText => searchText.trim().length > 1} />
                    </ReferenceInput>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="State Machine" path="state-machine">
                    <StateMachineField source="stateMachineData" />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="State Machine (JSON)" path="state-machine-json">
                    <JsonEditorInput label="State Machine" source="stateMachineData" jsonString={false} height="900px" />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
};