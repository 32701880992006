import { bluehillApiDomain } from './systemContext';
import { operationTypes } from './robotTaskOperationExecutions';

export const getEndpoint = (resource, type, params) => {
    if (resource === 'product_attributes' || resource === 'product_attributes_metadata' || resource==='robots') {
        return `${bluehillApiDomain()}/v2/${getResourcePath(resource, type, params)}`;
    }
    return `${bluehillApiDomain()}/v1/${getResourcePath(resource, type, params)}`;
};

const getResourcePath = (resource, type, params) => {
    if (type && type in pathGetters) {
        return pathGetters[type](resource, params);
    }
    if (resource === 'order_items') {
        return 'orders/~/items';
    } else if (resource === 'order_item_records') {
        return 'orders/~/items/~/records';
    } else if (resource === 'robot_task_operation_executions') {
        return 'robot_tasks';
    } else if (resource === 'store_products') {
        return `stores/~/products`;
    } else if (resource === 'stores_weekly_schedules') {
        const { id } = params;
        Object.keys(params).forEach(key => {
            delete params[key];
        });
        return `stores/${id}/weekly_schedules`;
    } else if (resource === 'product_attributes_metadata') {
        return 'products/~/attributes';
    } else if (resource === 'product_options_metadata') {
        return 'products/~/attributes/~/options';
    } else if (resource === 'product_options') {
        return `products/~/attributes/${params.attributeId || params?.meta?.attributeId}/options`;
    } else if (resource === 'product_program_key_relations') {
        return `robot_programs/~/products`;
    } else if (resource === 'robot_order_test_suites'){
        return `robot/~/order_test_suites`;
    } else if (resource === 'robot_order_test_cases') {
        return `robot/~/order_test_suites/${params.id}/test_cases`;
    } else if (resource === 'robot_order_test_case') {
        return `robot/~/order_test_suites/~/test_cases/${params.id}`;
    } else if (resource === 'robot_objects') {
        return `robot_configurations/${params.configuration}/objects`;
    } else if (resource === 'robot_imported_data_batches') {
        return `robot_configurations/~/imported_data_batches`;
    } else if (resource === 'robot_exported_data_batches') {
        return `robot_configurations/~/exported_data_batches`;
    } else if (resource === 'robot_action_operation_history') {
        return `operation_history`;
    } else {
        return resource;
    }
};

const pathGetters = {
    getOne(resource, params) {
        const { id } = params;
        if (resource === 'orders_email_receipt_info') {
            delete params['id'];
            return `orders/${id}/email_receipt_info`;
        } else if (resource === 'order_items') {
            delete params['id'];
            return `orders/~/items/${id}`;
        } else if (resource === 'order_item_records') {
            delete params['id'];
            return `orders/~/items/${id}/records`;
        } else if(resource === 'orders_email_receipt_info') {
            return `orders/${id}/email_receipt_info`;
        } else if (resource === 'orders_waiting_info') {
            return `orders/${id}/waiting_info`;
        } else if(resource === 'robot_ios') {
            const idArr = id.split('-');
            const robotId = idArr[0];
            const ioKey = idArr[1];
            delete params['id'];
            return `robots/${robotId}/ios/${ioKey}`;
        } else if(resource === 'robot_ios_control_data') {
            const idArr = params.id.split('-');
            const robotId = idArr[0];
            const ioControlKey = idArr[1];
            delete params['id'];
            return `robots/${robotId}/ios_control_data/${ioControlKey}`;
        } else if (resource === 'robot_common_ios_control_data') {
            return `robots/~/ios_control_data/${id}`;
        } else if (resource === 'robot_task_operation_executions') {
            delete params['id'];
            return `robot_tasks/${id}`;
        } else if (resource === 'stores_weekly_schedules') {
            Object.keys(params).forEach(key => {
                delete params[key];
            });
            return `stores/${id}/weekly_schedules`;
        } else if (resource === 'square_info') {
            return `stores/${id}/square_info`;
        } else if (resource === 'stripe_info') {
            return `stores/${id}/stripe_info`;
        } else if (resource === 'store_products') {
            return `stores/~/products/${id}`;
        } else if (resource === 'product_attributes') {
            return `products/${id}/attributes`;
        } else if (resource === 'product_attributes_metadata') {
            return `products/~/attributes/${id}`;
        } else if (resource === 'product_options_metadata') {
            return `products/~/attributes/~/options/${id}`;
        } else if (resource === 'product_groups_relation') {
            return `product_groups/${id}/products`;
        } else if (resource === 'product_metadata_groups_relation') {
            return `product_metadata/${id}/product_groups`;
        } else if (resource === 'get_receipt') {
            delete params['id'];
            return `orders/${id}/receipt`;
        } else if (resource === 'states') {
            return `countries/${params.countryCode}/states/${params.stateCode}`;
        } else if (resource === 'robot_order_test_cases') {
            return `robot/~/order_test_suites/${id}/test_cases`;
        } else if (resource === 'robot_order_test_case') {
            return `robot/~/order_test_suites/~/test_cases/${id}`;
        } else if (resource === 'robot_objects') {
            return `robot_configurations/~/objects/${id}`;
        } else if (resource === 'robot_keypoints') {
            return `robot_configurations/~/keypoints/${id}`;
        } else if (resource === 'reward_points') {
            return `customers/${id}/reward_points`;
        } else if (resource === 'issues_metadata') {
            var [ moduleId, issueKey ] = id.split('|');
            return `robots/~/modules/${moduleId}/issues_metadata/${issueKey}`;
        } else if (resource === 'robots/~/modules/computer/properties/volume') {
            return `robots/${id}/modules/computer/properties/volume`;
        } else if (resource === 'robot_watch_object_history') {
            const { configuration, objectId } = params;
            delete params.configuration;
            delete params.objectId;
            return `robot_configurations/${configuration}/objects/${objectId}/bounding_box`;
        } else if (resource === 'robot_exported_data_batches') {
            return `robot_configurations/~/exported_data_batches/${id}`;
        } else if (resource === 'robot_action_operation_history') {
            return `operation_history/${id}`;
        } else if (resource === 'display_config') {
            const { robotId } = params;
            delete params.robotId;
            return `robots/${robotId}/display_config`;
        } else {
            return `${resource}/${id}`;
        }
    },
    getList(resource, params) {
        if (resource === 'order_items') {
            return 'orders/~/items';
        } else if (resource === 'order_item_records') {
            return 'orders/~/items/~/records';
        } else if(resource === 'robot_ios') {
            return `robots/${params.robotId}/ios`;
        } else if (resource === 'robot_ios_control_data') {
            return `robots/${params.robotId}/ios_control_data`;
        } else if (resource === 'robot_common_ios_control_data') {
            return 'robots/~/ios_control_data';
        } else if (resource === 'store_products') {
            return `stores/${params.storeId}/products`;
        } else if (resource === 'product_attributes') {
            return `products/${params.id}/attributes`;
        } else if (resource === 'product_attributes_metadata') {
            return 'products/~/attributes';
        } else if (resource === 'product_options_metadata') {
            return `products/~/attributes/~/options`;
        } else if (resource === 'product_options') {
            return `products/~/attributes/${params.attributeId}/options`;
        } else if (resource === 'product_program_key_relations'){
            return `robot_programs/~/products`;
        } else if (resource === 'states') {
            return `countries/${params.countryCode}/states`;
        } else if (resource === 'robot_order_test_suites'){
            return `robot/~/order_test_suites`;
        } else if (resource === 'robot_order_test_cases'){
            return `robot/~/order_test_suites/${params.id}/test_cases`;
        } else if (resource === 'robot_order_test_case') {
            return `robot/~/order_test_suites/~/test_cases/${params.id}`;
        } else if (resource === 'robot_objects') {
            return `robot_configurations/${params.configuration}/objects`;
        } else if (resource === 'robot_watch_object_types') {
            const { configuration } = params;
            delete params['configuration'];
            return `robot_configurations/${configuration}/objects/~/watch_objects/~/types`;
        } else if (resource === 'robot_watch_object_history') {
            const { configuration, objectId } = params;
            delete params['configuration'];
            delete params['objectId'];
            return `robot_configurations/${configuration}/objects/${objectId}/watch_object_history`;
        } else if (resource === 'robot_keypoints') {
            return `robot_configurations/${params.configuration}/keypoints`;
        } else if (resource === 'customer_coupons') {
            return `customer_coupons/${params.id}`;
        } else if (resource === 'customer_reward_point_history') {
            return `customers/${params.id}/reward_point_history`;
        } else if (resource === 'customer_referral_transaction') {
            return `customers/~/referral_transaction`;
        } else if (resource === 'issues_metadata') {
            return `robots/~/modules/~/issues_metadata`;
        } else if (resource === 'robot_imported_data_batches') {
            return `robot_configurations/~/imported_data_batches`;
        } else if (resource === 'robot_exported_data_batches') {
            return `robot_configurations/~/exported_data_batches`;
        } else if (resource === 'robot_group_relations') {
            return `robots/${params.robotId}/groups`;
        } else if (resource === 'operation_history') {
            if (params.subobject) {
                Object.keys(params.subobject).forEach((key) => {
                    params[`subobject.${key}`] = params.subobject[key];
                });
                delete params.subobject;
            }
            return resource;
        } else if (resource === 'robot_action_operation_history') {
            if (params.subobject) {
                Object.keys(params.subobject).forEach((key) => {
                    params[`subobject.${key}`] = params.subobject[key];
                });
                delete params.subobject;
            }
            return `operation_history`;
        } else {
            return resource;
        }
    },
    create(resource, params) {
        if(resource === 'robot_ios') {
            return `robots/${params.data.robotId}/ios`;
        } else if (resource === 'robot_ios_control_data') {
            return `robots/${params.data.robotId}/ios_control_data`;
        } else if (resource === 'robot_task_operation_executions') {
            const { operationType, id, ...data } = params.data;
            const { fieldFilter } = operationTypes[operationType];
            params.data = ['robotId', 'configuration', 'actionIds', ...fieldFilter].reduce((result, key) => Object.assign(result, { [key]: data[key] }), {});
            return `robot_tasks/${id}/operations/${operationType}/executions`;
        } else if (resource === 'store_products') {
            return `stores/~/products`;
        } else if (resource === 'store_products_clone_executions') {
            const { productId, ...data } = params.data;
            params.data = data;
            return `stores/~/products/${productId}/operations/clone/executions`;
        } else if (resource === 'product_attributes_metadata') {
            return 'products/~/attributes';
        } else if (resource === 'store_products_bulkclone_executions') {
            return 'stores/~/products/~/operations/clone/executions';
        } else if (resource === 'product_options_metadata') {
            return 'products/~/attributes/~/options';
        } else if (resource === 'product_program_key_relations'){
            return `robot_programs/~/products`;
        } else if (resource === 'robot_product_programKey_relations_bulkclone_executions') {
            return 'robot_programs/~/products/operations/clone/executions';
        } else if (resource === 'robot_attributes_bulkclone_executions') {
            return 'robot_attributes/~/operations/clone/executions';
        } else if (resource === 'orders_partially_refund_executions'){
            const { id } = params;
            delete params['id'];
            delete params['data'];
            return `orders/${id}/operations/partially_refund/executions` 
        } else if (resource === 'orders_cancel_and_refund_excutions'){
            const { id } = params;
            delete params['id'];
            delete params['data'];
            return `orders/${id}/operations/cancel_and_refund/executions` 
        } else if (resource === 'robot_ios_bulkclone_executions') {
            return 'robots/~/ios/~/operations/clone/executions';
        } else if (resource === 'robot_order_test_suites'){
            return `robot/~/order_test_suites`;
        } else if (resource === 'robot_objects') {
            return `robot_configurations/${params.configuration}/objects`;
        } else if (resource === 'robot_keypoints') {
            const { configuration, ...data } = params.data;
            return `robot_configurations/${configuration}/keypoints`;
        } else if (resource === 'add_reward_points_executions') {
            const { customerId } = params;
            delete params['customerId'];
            return `customers/${customerId}/reward_points/operations/add_reward_points/executions`
        } else if (resource === 'mark_as_used_executions') {
            const { id } = params;
            delete params['Id'];
            return `customer_coupons/${id}/operations/mark_as_used/executions`
        } else if (resource === 'issues_metadata') {
            return `robots/~/modules/~/issues_metadata`;
        } else if (resource === 'robots/~/play_voice') {
            return `robots/${params.id}/play_voice`;
        } else if (resource === 'robot_operation_executions') {
            const { robotId, operationId } = params;
            delete params['robotId'];
            delete params['operationId'];
            return `robots/${robotId}/operations/${operationId}/executions`;
        } else if (resource === 'robot_imported_data_batches') {
            return `robot_configurations/${params.data.configuration}/imported_data_batches`;
        } else if (resource === 'robot_exported_data_batches') {
            return `robot_configurations/${params.data.configuration}/exported_data_batches`;
        } else {
            return resource;
        }
    },
    updateMany(resource, params) {
        if(resource === 'store_products') {
            return `stores/~/products`;
        } else if(resource === 'robot_ios') {
            return `robots/~/ios`;
        } else {
            return resource;
        }
    },
    getManyReference(resource, params) {
        if(resource === 'customer_coupons') {
            return `customer_coupons/${params.id}`;
        } else if (resource === 'customer_reward_point_history') {
            return `customers/${params.id}/reward_point_history`;
        } else if (resource === 'store_weekly_schedules') {
            return `stores/${params.id}/weekly_schedules`;
        } else {
            return resource;
        }
    }
};
