import React from "react";

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

export const PostSubmitPopUp = ({ dialogState, setDialogState, dialogTitle, dialogTextContent, children, ...props }) => {
  return (<Dialog 
    open={dialogState.popup}
    onClose={() => setDialogState(prev => ({ ...prev, popup: false }))}
>
    <DialogTitle>{dialogTitle}</DialogTitle>
    <DialogContent>
        <DialogContentText>
            {dialogTextContent}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        {children}
    </DialogActions>
</Dialog>)
};
