import React, { createContext, useContext } from 'react';

export const ValueContext = createContext({});

export const ValueProvider = ({ value, children }) => {
  const parentValue = useValueContext() ?? {};
  return <ValueContext.Provider value={{ ...parentValue, ...(value ?? {}) }}>{children}</ValueContext.Provider>;
}

export function useValueContext(key) {
  const context = useContext(ValueContext);
  return key ? context[key] : context;
}
