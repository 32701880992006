import React, { useMemo } from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    DeleteButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    Show,
    SimpleShowLayout,
    SimpleFormIterator,
    ArrayInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayField,
    useListContext,
    useRecordContext,
} from 'react-admin';
import { BluehillPagination } from './bluehillPagination';

const filters = [
    <TextInput label="Task Group Key" source="partialTaskGroupKey" alwaysOn />,
];

export const TaskGroupKeyList = props => (
    <List {...props} perPage={50} sort={{ field: '', order: '' }}
        filters={filters} pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="taskGroupKey" />
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const TaskGroupKeyCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm >
                <TextInput source="taskGroupKey" />
                <ArrayInput source="taskKeys" allowduplicates="true">
                    <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                        <ReferenceInput reference="robot_task_keys" fullWidth >
                            <AutocompleteInput optionText="taskKey"
                                filterToQuery={searchText => ({ partialTaskKey: searchText })}
                                shouldRenderSuggestions={searchText => searchText.trim().length >= 1} />
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

export const TaskGroupKeyEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextField source="taskGroupKey" />
            <ArrayInput source="taskKeys" allowduplicates="true">
                <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                    <ReferenceInput reference="robot_task_keys" fullWidth >
                        <AutocompleteInput optionText="taskKey"
                            filterToQuery={searchText => ({ partialTaskKey: searchText })}
                            shouldRenderSuggestions={searchText => searchText.trim().length >= 1} />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const TaskGroupKeyShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="taskGroupKey" />
                <ReferenceArrayField
                    reference="robot_task_keys"
                    source="taskKeys"
                    allowduplicates="true"
                    fullWidth>
                        <ListContent source="taskKeys" />
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

const ListContent = (props) => {
    const { source } = props;
    const { data } = useListContext();
    const record = useRecordContext();

    const dataArray = useMemo(() => {
        const ids = record[source] || [];
        if (data?.length && ids?.length) {
            const tempMap = {};
            data.forEach(e => tempMap[e?.id] = e);
            return ids.map((e, index) => tempMap[e]);
        }
        return [];
    }, [record, data, source]);

    return (
        <Datagrid bulkActionButtons={false} data={dataArray} {...props}>
            <TextField source="taskKey" />
        </Datagrid>
    );
};
