import * as React from "react";
import { useState } from "react";
import {
    Create,
    List,
    SimpleList,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
    SelectInput,
    TextField,
    DateField,
    useEditController,
    useDataProvider,
    useNotify,
    required,
    useRefresh,
    Toolbar,
    SaveButton,
    useRedirect,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { BluehillPagination } from './bluehillPagination';
import { PostSubmitPopUp } from "./components/PostSubmitPopUp";
import { ExecuteOperationButton } from "./components/ExecuteOperationButton";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { SYNC_CLOUD_DATA_OPERATION_ID } from "./robotOperationConstants";

const actionOptionRenderer = action => {
    if(!action) {
        return '';
    }
    return action.id + ": " + action.name;
};

export const operationTypes = {
    apply_actions_offset: {
        label: 'Apply Actions Offset',
        value: 'apply_actions_offset',
        fields() {
            return (
                <>
                    <NumberInput source="xOffset" label="X offset (mm)" defaultValue={0} /><br />
                    <NumberInput source="yOffset" label="Y offset (mm)" defaultValue={0} /><br />
                    <NumberInput source="zOffset" label="Z offset (mm)" defaultValue={0} /><br />
                    <NumberInput source="tiltOffset" label="Tilt offset (degree)" defaultValue={0} /><br />
                    <NumberInput source="facingOffset" label="Facing offset (degree)" defaultValue={0} />
                </>
            );
        },
        fieldFilter: ['xOffset', 'yOffset', 'zOffset', 'tiltOffset', 'facingOffset']
    },
    change_actions_duration: {
        label: 'Change Actions Duration',
        value: 'change_actions_duration',
        fields() {
            return (
                <>
                    <p>To accelerate the action, use a number between 0.5 and 1</p>
                    <p>To decelerate the action, use a number between 1 and 2</p>
                    <NumberInput source="durationRatio" label="Duration ratio" defaultValue={1.0} min={0.5} max={2.0} /><br/>
                </>
            );
        },
        fieldFilter: ['durationRatio']
    }
};

function DynamicFields() {
    const operationType = useWatch({ name: 'operationType' });

    return (
        <>
            <SelectInput source="operationType" validate={[required()]} defaultValue="apply_actions_offset"
                choices={Object.keys(operationTypes).map(key => ({
                    id: key, name: operationTypes[key].label
                }))}
            /><br />
            {operationTypes[operationType]?.fields()}
        </>
    );
}

function Aside(props) {
    const { record } = useEditController(props);
    if (!record) {
        return null;
    }
    return (
        <aside className="list-page">
            <h3 style={{margin: '0 1em 1em', fontSize: '1em', fontWeight: 'normal'}}>{`Operation History - Configuration ${record.configuration}`}</h3>
            <List
                resource="operation_history"
                filter={{ "object.id": record.configuration, "object.type": 'ROBOT_CONFIGURATION' }}
                sort={{ field: 'operationTime', order: 'DESC' }}
                actions={null}
                exporter={false}
                style={{
                    marginLeft: '1em',
                    minWidth: '18em',
                }}
                pagination={<BluehillPagination showButtonText={false} className="aside" />}
            >
                <SimpleList
                    primaryText={<TextField source="operationSubType" style={{ wordBreak: 'break-word' }} />}
                    secondaryText={<DateField source="operationTime" showTime />}
                    tertiaryText={<>(<TextField source="operatorInfo.name" />)</>}
                    linkType="show"
                />
            </List>
        </aside>
    );
}

// robot_tasks/21084/operations/apply_actions_offset/executions

function TaskOperationExecuteFormToolbar() {
    const notify = useNotify();
    const redirect = useRedirect();
    const { getValues, reset } = useFormContext();
    const record = getValues();
    const robotId = record?.robotId;
    const [dialogState, setDialogState] = useState({ popup: false });
    return (
        <Toolbar>
            <SaveButton
                label="Save"
                type="button"
                mutationOptions={{
                    onSuccess() {
                        notify('Successfully submitted. Waiting for execution.');
                        reset();
                        redirect(false);
                        setDialogState({ popup: true, robotId: 0 })
                    }
                }}
            />
            <PostSubmitPopUp dialogState={dialogState} setDialogState={setDialogState} dialogTitle="Sync Cloud Data?" 
                dialogTextContent="Successfully submitted action adjustment request.">
                <ExecuteOperationButton operationId={SYNC_CLOUD_DATA_OPERATION_ID} label="SYNC CLOUD DATA" startIcon={<CloudSyncIcon />}
                    operationName="Sync Cloud Data" robotId={robotId} disabled={false} />
            </PostSubmitPopUp>
        </Toolbar>
    );
}

export const TaskOperationExecute = props => {
    const { record } = useEditController(props);
    const { state } = useLocation();

    return (
        <Create {...props}
            aside={<Aside {...props} />}
        >
            <SimpleForm
                defaultValues={{
                    taskId: record?.id,
                    operationType: 'apply_actions_offset'
                }}
                toolbar={<TaskOperationExecuteFormToolbar />}
                // onSubmit={onSubmit}
            >
                {record
                    ? (
                        <>
                            <TextInput source="id" defaultValue={ record.id } disabled /><br/>
                            <NumberInput source="configuration" defaultValue={ record.configuration } disabled /><br/>
                            <NumberInput source="robotId" defaultValue={ record.configuration } />
                            <ReferenceArrayInput
                                reference="robot_actions"
                                source="actionIds"
                                label="Actions"
                                filter={{ ids: state?.actionIds ?? record?.actionIds, fields: ['id', 'name'] }}
                                sort={{field:'', order:'DESC'}}
                                >
                                <CheckboxGroupInput
                                    fullWidth
                                    optionText={actionOptionRenderer}
                                    validate={[required()]}
                                />
                            </ReferenceArrayInput>
                        </>
                    )
                    : null
                }
                <DynamicFields />
            </SimpleForm>
        </Create>
    );
};