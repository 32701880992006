import * as React from "react";
import {
    List,
    Show,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    SimpleShowLayout,
    BooleanField,
    useShowContext,
    useDataProvider,
    SimpleForm,
    TabbedShowLayout,
    FunctionField,
    ReferenceField,
    NumberField,
    DateTimeInput,
    SelectInput,
    ReferenceInput,
    useNotify,
    useRefresh,
    Create,
    Toolbar,
    ShowButton,
    SaveButton, useRecordContext, Confirm, ReferenceManyField,
} from 'react-admin';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { BluehillPagination } from './bluehillPagination';
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { makeStyles } from '@mui/styles';

const filters = [
    <TextInput key="email" source="email" alwaysOn />,
    <TextInput label="Customer Id" key="customerId" source="customerId" alwaysOn />,
];

const useStyle = makeStyles({
    button: {
        backgroundColor: '#BEE0ED',
        "border-radius": '4px',
        textTransform: 'none',
        padding: '5px 10px',
        width: 'flex',
        height:'35px',
        "margin": '5px',
    },inputBox: {
        marginRight: -1,
        height: 48,
        width: 150,
        position: 'relative',
        bottom: 8,
    },markAsUsedButton: {
        backgroundColor:'#BEE0ED',
        "border-radius": '4px',
        textTransform :'none',
        "margin-right": '15px',
        "padding" : '10px',
        width : '150px'
    },disabledButton: {
        backgroundColor:'#dbedf3',
        "border-radius": '4px',
        textTransform :'none',
        opacity: 0.5,
        "padding" : '10px',
        "margin-right": '15px',
        width : '150px',
        height: 48,
    },
});

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export const MarkAsUsedButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const dataProvider = useDataProvider();
    const classes = useStyle();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    let buttonClass = classes.markAsUsedButton
    let label = "Mark as Used"
    let status = false;
    if (record.used) {
        status = true;
        label = "Used"
        buttonClass = classes.disabledButton
    }
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = ()  => {
        dataProvider.create(`mark_as_used_executions`, {
            id: record.couponCode,
        })
            .then(() => {
                refresh();
                notify('Coupon marked as used');
            })
            .catch((e) => {
                notify(e.message);
            });
        setOpen(false);
    }
    return (
        <>
            <Button onClick={handleClick} disabled={status} className={buttonClass}>
                {label}
            </Button>
            <Confirm
                isOpen={open}
                title="Mark coupon as used"
                content="Are you sure you want to mark this coupon as used?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export const AddCouponButton = ({ customerId, basePath, resource}) => {
    const dataProvider = useDataProvider();
    const classes = useStyle();
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    async function onSubmit({ startTime, couponTemplateId }) {
        try {
            await dataProvider.create('customer_coupons', {
                data: {
                    couponTemplateId: couponTemplateId,
                    customerId: customerId,
                    startTime: startTime.getTime()
                }
            });
            notify('Coupon added');
            setOpen(false);
            refresh();
        } catch (e) {
            notify(e.message);
        }
    }
    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={() => setOpen(true)} label="Add Coupon" className={classes.button}>
                    Add Coupon
                </Button>
            </Box>
            <Dialog open={open} maxWidth="md" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle id="simple-dialog-title"> Add coupon </DialogTitle>
                <DialogContent>
                    <Create resource={resource}>
                        <SimpleForm onSubmit={onSubmit} toolbar={<CustomToolbar />}>
                            <ReferenceInput
                                label="Add coupon"
                                source="couponTemplateId"
                                reference="coupon_templates"
                                allowEmpty
                            >
                                <SelectInput optionText="couponTemplateId" />
                            </ReferenceInput>
                            <Box display="flex" alignItems="center">
                                <DateTimeInput defaultValue={new Date()} label="coupon started date" source="startTime" alwaysOn />
                            </Box>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const AddRewardButton = ({ customerId, basePath, resource}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyle();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const handleKeyPress = (event) => {
        const { value } = event.target;
        if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();
        } else if (value.indexOf('.') !== -1 && event.key === '.') {
            event.preventDefault();
        }
    }

    async function onSubmit({ additionalPoints }) {
        try {
            await dataProvider.create('add_reward_points_executions', {
                customerId: customerId,
                data: {
                    addendPoints: additionalPoints,
                }
            });
            notify('Reward points added!');
            setOpen(false);
            refresh();
        } catch (e) {
            notify(e.message);
        }
    }
    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={() => setOpen( true)} label="Add Reward Points" className={classes.button}>
                    Add Reward Points
                </Button>
            </Box>
            <Dialog open={open} maxWidth="md" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle id="simple-dialog-title">Add reward points</DialogTitle>
                <DialogContent>
                    <Create resource={resource}>
                        <SimpleForm onSubmit={onSubmit} toolbar={<CustomToolbar />}>
                            <TextInput
                                className={classes.inputBox}
                                source="additionalPoints"
                                onKeyPress={handleKeyPress}
                            />
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const CustomerList = props => (
    <List {...props}  perPage={50} sort={{field:'', order:''}} filters={filters}
          filterDefaultValues={{}} pagination={<BluehillPagination />}>
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <TextField label="Customer Id" source="customerId" />
            <TextField source="fullName" />
            <TextField source="email" />
            <TextField source="accountType" />
            <DateField source="createdTime" />
        </Datagrid>
    </List>
);

export const CustomerShow = props => (
    <Show>
        <TabbedShowLayout syncWithLocation={false}>
            <TabbedShowLayout.Tab label="Basic Info">
                <TextField source="accountType" />
                <TextField label="Customer Id" source="customerId" />
                <TextField source="fullName" />
                <TextField source="familyName" />
                <TextField source="givenName" />
                <TextField source="userName" />
                <TextField source="email" />
                <DateField source="createdTime" showTime />
                <DateField source="updatedTime" showTime />
                <BooleanField source="emailVerified" />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Coupons">
                <CouponShowTab />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Reward Points">
                <RewardPointsShowTab />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Orders">
                <OrderShowTab />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Referral Transactions">
                <ReferralShowTab />
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </Show>
);

const CouponShowTab = () => {
    const record = useRecordContext();
    const { customerId } = record;
    const { resource } = useShowContext();
    return (
        <Grid container>
            <Grid item xs={9}>
                <ReferenceManyField reference="customer_coupons" filter={{ id: record.id }} source="id" target="customerId" pagination={<BluehillPagination />} perPage={50}>
                    <Datagrid>
                        <DateField label="Start Date" source="startTime" showTime />
                        <DateField label="Expired On" source="endTime" showTime />
                        <TextField source="couponType" />
                        <FunctionField
                            label="Status"
                            render={(record) => {
                                let status = "";
                                let color = "";
                                if (record.used) {
                                    status = "Redeemed";
                                    color = "black";
                                } else if (record.expired) {
                                    status = "Expired";
                                    color = "red";
                                } else if (!record.started) {
                                    status = "Upcoming";
                                    color = "blue";
                                } else {
                                    status = "Active";
                                    color = "green";
                                }
                                return <span style={{color}}>{status}</span>;
                            }}
                        />
                        <MarkAsUsedButton>Mark coupon as used</MarkAsUsedButton>
                    </Datagrid>
                </ReferenceManyField>
            </Grid>
            <Grid item xs={3}>
                <AddCouponButton customerId={customerId} resource={resource} />
            </Grid>
        </Grid>
    );
}

const useRewardPoints = (id) => {
    const dataProvider = useDataProvider();
    const [rewardPoints, setRewardPoints] = useState();

    useEffect(() => {
        dataProvider.getOne("reward_points", { id: id })
            .then(({ data }) => {
                setRewardPoints(data);
            })
            .catch(err => console.error(err));
    }, [id]);

    return { rewardPoints, setRewardPoints };
}

const RewardPointsShowTab = () => {
    const record = useRecordContext();
    const { customerId } = record;
    const { rewardPoints } = useRewardPoints(customerId);
    const { resource } = useShowContext();
    return (
        <>
            <SimpleShowLayout record={rewardPoints}>
                <TextField label="Total Reward Points" source="points" />
                <Divider style={{marginBottom: '20px', marginTop: '20px'}} />
            </SimpleShowLayout>
            <Grid container>
                <Grid item xs={9}>
                    <ReferenceManyField lable="Reward Points History" reference="customer_reward_point_history" filter={{ id: record.id }} source="id" target="customerId" pagination={<BluehillPagination />} perPage={50}>
                        <Datagrid>
                            <TextField label="Operation ID" source="operationId" />
                            <DateField source="operationTime" showTime />
                            <FunctionField
                                label="Points"
                                render={(record) => {
                                    let color = "black";
                                    if (record.points < 0) {
                                        color = "red";
                                    } else if (record.points > 0) {
                                        color = "green";
                                    }
                                    return <span style={{color}}>{record.points}</span>;
                                }} />
                        </Datagrid>
                    </ReferenceManyField>
                </Grid>
                <Grid item xs={3}>
                    <AddRewardButton customerId={customerId} resource={resource} />
                </Grid>
            </Grid>
        </>
    );
};

const OrderShowTab = () => {
    const record = useRecordContext();
    const { customerId } = record;

    return (
        <ReferenceManyField reference="orders" filter={{ customerId }} source="id" target="customerId" pagination={<BluehillPagination />} perPage={50}>
            <Datagrid bulkActionButtons={false}>
                <TextField label="Order Id" source="orderId" />
                <ReferenceField label="Store Name" link={false} source="storeId" reference="stores">
                    <TextField source="storeName" />
                </ReferenceField>
                <TextField label="Customer Name" source="customer.fullName" />
                <TextField label="Order Status" source="orderStatus" />
                <TextField label="Order Source" source="orderSource" />
                <NumberField label="Items Counts" source="items.length" />
                <FunctionField 
                label="Total Price" 
                render={record => 
                    isNaN(record.priceInfo.grandTotal)
                    ? "$0"
                    : `$${record.priceInfo.grandTotal/100}`} 
                />
                <DateField source="createdTime" showTime />
                <ShowButton />
            </Datagrid>
        </ReferenceManyField>
    );
}

function ReferralShowTab() {
    const record = useRecordContext();
    const { customerId } = record;

    return (
        <ReferenceManyField reference="customer_referral_transaction" filter={{ referrerCustomerId: customerId }} source="id" target="customerId" perPage={200}>
            <Datagrid bulkActionButtons={false}>
                <ReferenceField source="referredCustomerId" reference="customers">
                    <TextField source="referredCustomerName" />
                </ReferenceField>
                <BooleanField source="firstOrderStatusDone" />
                <BooleanField source="rewardIssued" />
                <DateField source="createdTime" showTime />
                <DateField source="updatedTime" showTime />
                <ShowButton />
            </Datagrid>
        </ReferenceManyField>
    );
}