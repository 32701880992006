import React, { useCallback, useContext } from 'react';
import { cloneDeep } from 'lodash';
import {
    Create,
    SimpleForm,
    ReferenceInput,
    required,
    ArrayInput,
    SelectInput,
    SimpleFormIterator,
    useInput,
    SimpleFormIteratorItemContext,
} from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Menu, MenuItem } from '@mui/material';
import {
    ReactFlow,
    BaseEdge,
    Controls,
    ControlButton,
    Background,
    MarkerType,
    EdgeLabelRenderer,
    useNodesState,
    useEdgesState,
    addEdge,
    getBezierPath,
    useNodes
} from '@xyflow/react';
import { useWatch, useForm, useFieldArray } from 'react-hook-form';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import { SmartStepEdge } from '@tisoap/react-flow-smart-edge';
import '@xyflow/react/dist/style.css';

import AutocompleteInput from './BaseAutocompleteInput';
import { useValueContext, ValueProvider } from './ValueContext';

function ConditionSelect({ source }) {
    const { id, field } = useInput({ source });
    const { robotConditions = [], editingEdge } = useValueContext() ?? {};
    const [transition] = editingEdge ?? [];
    const choices = robotConditions;
        // .filter((item) => transition?.conditionIds.includes(item.id)); // for limiting choices

    return (
        <AutocompleteInput
            {...field}
            source={source}
            choices={choices}
        />
    );
}

function ConditionResultSelect({ source }) {
    const { id, field } = useInput({ source });
    const row = useWatch({ name: 'expectedConditionResults' });
    const { index } = useContext(SimpleFormIteratorItemContext);
    const { robotConditions = [], editingEdge } = useValueContext() ?? {};
    // const [transition, outputIndex] = editingEdge ?? [];
    const condition = robotConditions.find(({ id }) => id === row[index].conditionId);
    // const otherOutputs = transition?.outputs.filter((item, i) => i !== outputIndex);
    const choices = condition?.possibleValues.map((item) => ({ id: item, name: item })) ?? [];
    return (
        <SelectInput
            id={id}
            {...field}
            source={source}
            choices={choices}
        />
    );
}

function StateClassSelect({ source }) {
    const s = source || 'stateClassId'

    return (
        <ReferenceInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={200}
            source={s}
            reference="robot_state_classes"
            fullWidth
        >
            <AutocompleteInput
                optionValue="id"
                optionText="name"
                validate={[required()]}
                label="State Class"
                filterToQuery={searchText => ({ name: searchText })}
            />
        </ReferenceInput>
    );
}

function TaskGroupKeySelect({ source }) {
    return (
        <ReferenceInput
            sort={{ field: 'taskGroupKey', order: 'ASC' }}
            perPage={200}
            source={source || 'taskGroupKey'}
            reference="robot_task_group_keys"
            fullWidth
        >
            <AutocompleteInput
                optionValue="taskGroupKey"
                optionText="taskGroupKey"
                label="Task Group Key"
                queryFields={['taskGroupKey']}
                filterToQuery={searchText => ({ taskGroupKey: searchText })}
            />
        </ReferenceInput>
    );
}

/**
 * 计算 SVG 路径的中心位置坐标
 * @param {number} sourceX - 起点 X
 * @param {number} sourceY - 起点 Y
 * @param {number} aroundX - 中间关键点 X
 * @param {number} targetX - 终点 X
 * @param {number} targetY - 终点 Y
 * @param {number} [steps=100] - 分割步长（越大越精确）
 * @returns {{x: number, y: number}} 中心坐标
 */
function calculatePathCenter(sourceX, sourceY, aroundX, targetX, targetY, steps = 100) {
    // 定义第一个贝塞尔曲线段（C 命令）
    const segment1 = {
        type: "cubic",
        p0: { x: sourceX, y: sourceY },
        p1: { x: sourceX, y: sourceY + 50 },
        p2: { x: aroundX, y: sourceY + 100 },
        p3: { x: aroundX, y: sourceY }
    };

    // 定义第二个贝塞尔曲线段（S 命令）
    // S 命令的第一个控制点是前一个段第二个控制点的反射
    const sP1x = 2 * segment1.p3.x - segment1.p2.x; // = aroundX
    const sP1y = 2 * segment1.p3.y - segment1.p2.y; // = sourceY - 100
    const segment2 = {
        type: "cubic",
        p0: { x: aroundX, y: sourceY },
        p1: { x: sP1x, y: sP1y },
        p2: { x: aroundX, y: targetY - 100 },
        p3: { x: targetX, y: targetY }
    };

    // 计算两段曲线的总长度，并找到中点位置
    const totalLength = getBezierLength(segment1, steps) + getBezierLength(segment2, steps);
    const halfLength = totalLength / 2;

    // 查找中点所在的曲线段及参数 t
    let accumulatedLength = 0;
    let targetSegment = null;
    let tInSegment = 0;

    // 检查第一段
    const length1 = getBezierLength(segment1, steps);
    if (halfLength <= length1) {
        targetSegment = segment1;
        tInSegment = halfLength / length1;
    } else {
        // 检查第二段
        accumulatedLength = length1;
        const length2 = getBezierLength(segment2, steps);
        targetSegment = segment2;
        tInSegment = (halfLength - accumulatedLength) / length2;
    }

    // 计算中点坐标
    return getBezierPoint(targetSegment, tInSegment);
}

// 辅助函数：计算贝塞尔曲线在参数 t 处的坐标
function getBezierPoint(segment, t) {
    const { p0, p1, p2, p3 } = segment;
    const x = (1 - t) ** 3 * p0.x +
        3 * (1 - t) ** 2 * t * p1.x +
        3 * (1 - t) * t ** 2 * p2.x +
        t ** 3 * p3.x;

    const y = (1 - t) ** 3 * p0.y +
        3 * (1 - t) ** 2 * t * p1.y +
        3 * (1 - t) * t ** 2 * p2.y +
        t ** 3 * p3.y;

    return { x, y };
}

// 辅助函数：近似计算贝塞尔曲线长度
function getBezierLength(segment, steps) {
    let length = 0;
    let prevPoint = segment.p0;

    for (let i = 1; i <= steps; i++) {
        const t = i / steps;
        const currentPoint = getBezierPoint(segment, t);
        const dx = currentPoint.x - prevPoint.x;
        const dy = currentPoint.y - prevPoint.y;
        length += Math.sqrt(dx * dx + dy * dy);
        prevPoint = currentPoint;
    }

    return length;
}

function CustomBezierEdge(props) {
    const { sourceX, sourceY, targetX, targetY, label, source, id, markerEnd } = props;
    const nodes = useNodes();
    const sourceNode = nodes.find((node) => node.id === source);
    const [bezierPath, labelX, labelY] = getBezierPath(props);
    const aroundX = sourceX + (sourceNode.measured ? sourceNode.measured?.width / 2 + 20 : 100) * Math.sign(sourceX - targetX);
    const simple = sourceY - targetY < 0;
    const edgePath = simple
        ? bezierPath
        : `M ${sourceX} ${sourceY}
        C ${sourceX} ${sourceY + 50}, ${aroundX} ${sourceY + 100}, ${aroundX} ${sourceY}
        S ${aroundX} ${targetY - 100}, ${targetX} ${targetY}`;
    const center = calculatePathCenter(sourceX, sourceY, aroundX, targetX, targetY);

    return (
        <>
            <BaseEdge {...props} path={edgePath} />
            <EdgeLabelRenderer>
                <div className="state-machine-edge-label" style={{
                    transform: simple ? `translate(-50%, -50%) translate(${labelX}px,${labelY}px)` : `translate(-50%, -50%) translate(${center.x}px,${center.y}px)`,
                }}>
                    {label}
                </div>
            </EdgeLabelRenderer>
        </>
    );
}

function makeNode(item, index) {
    const id = item.stateId ?? Math.random().toString(36).slice(2);
    return ({
        id,
        position: {
            x: 0,
            y: index * 100,
            ...item.visulizationInfo,
        },
        data: {
            label: item.stateClassId || item.stateId,
        },
        style: {
            width: 'auto',
        }
    });
}

function makeTransitionEdges(item, robotConditions) {
    return item.outputs.map((branch, branchIndex) => {
        const conditions = branch.expectedConditionResults.map(({ conditionId, expectedResult }) => {
            const condition = robotConditions.find(({ id }) => id === conditionId);
            return condition ? <dl key={conditionId}><dt>{condition.name}</dt><dd>{expectedResult}</dd></dl> : null;
        });
        return {
            id: `${item.from.stateId}-${branchIndex}-${branch.to.stateId}`,
            source: item.from.stateId,
            target: branch.to.stateId,
            type: 'customBezier',
            label: (
                <>
                    {conditions.length ? conditions : null}
                    <div className="group-key">{branch?.transition?.taskGroupKey}</div>
                </>
            ),
            labelShowBg: true,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20
            }
        };
    });
}

function makeEdges(transitions = [], robotConditions = []) {
    return (transitions).reduce((result, item) => result.concat(makeTransitionEdges(item, robotConditions)), []);
}

const nodeTypes = {
    // groupNode: GroupNode
};

const edgeTypes = {
    customBezier: CustomBezierEdge,
    // smart: SmartStepEdge,
};

export default function FlowCanvas({ value, onChange, disabled }) {
    const { robotConditions = [] } = useValueContext();
    const [nodes, setNodes, onNodesChange] = useNodesState((value?.states ?? []).map(makeNode));
    const [edges, setEdges, onEdgesChange] = useEdgesState(makeEdges(value?.transitions ?? [], robotConditions));

    const [newNode, setNewNode] = React.useState(null);
    const [openingNode, setOpeningNode] = React.useState(null);
    const [editingNode, setEditingNode] = React.useState(null);

    const [editingEdge, setEditingEdge] = React.useState(null);
    const [openingTransition, setOpeningTransition] = React.useState(null);

    const [menuAnchor, setMenuAnchor] = React.useState(null);

    const createMenuAnchor = useCallback((ev) => {
        const anchor = document.createElement('div');
        anchor.style = `
            position: absolute;
            top: ${ev.clientY}px;
            left: ${ev.clientX}px;
            width: 0;
            height: 0;
        `
        document.body.appendChild(anchor);
        setMenuAnchor(anchor);
    }, []);

    const handleNodesChange = useCallback((nds) => {
        onNodesChange(nds);
        const positions = nds.filter((event) => !event.dragging && event.type === 'position');
        if (positions.length) {
            const next = cloneDeep(value);
            positions.forEach((node) => {
                const item = next.states.find((item) => item.stateId === node.id);
                if (item) {
                    item.visulizationInfo = { ...node.position };
                }
            });
            onChange(next);
        }
        const removed = nds.filter((event) => event.type === 'remove');
        if (removed.length) {
            const next = cloneDeep(value);
            // next.states = next.states.filter((item) => removed.every((node) => node.id !== item.stateId));
            // next.transitions = next.transitions.filter((item) => removed.every((node) => item.from.stateId !== node.id && item.to.stateId !== node.id));
            removed.forEach((node) => {
                const stateIndex = next.states.findIndex((item) => item.stateId === node.id);
                next.states.splice(stateIndex, 1);

                const fromIndex = next.transitions.findIndex((item) => item.from.stateId === node.id);
                if (fromIndex >= 0) {
                    next.transitions.splice(fromIndex, 1);
                }

                const toTransitions = next.transitions.filter((item) => item.outputs.some((o) => o.to.stateId === node.id));
                toTransitions.forEach((item) => {
                    const outputIndex = item.outputs.findIndex((o) => o.to.stateId === node.id);
                    item.outputs.splice(outputIndex, 1);
                    if (!item.outputs.length) {
                        next.transitions = next.transitions.filter((t) => t !== item);
                    }
                });
            });
            onChange(next);
        }
    }, [value, onChange, onNodesChange]);

    const onAddNode = useCallback(() => {
        setNewNode({});
    }, []);

    const onNodeAdded = useCallback((data) => {
        const next = cloneDeep(value);
        if (!next.states) {
            next.states = [];
        }
        if (!next.transitions) {
            next.transitions = [];
        }
        data.stateId = Math.random().toString(36).slice(2);
        data.visulizationInfo = { x: next.states.length * 10, y: next.states.length * 10 };
        next.states.push(data);
        onChange(next);
        setNodes((nds) => next.states.map(makeNode));
        setNewNode(null);
    }, [onChange, setNodes, value]);

    const onNodeDoubleClick = useCallback((ev, node) => {
        const item = value.states.find((item) => item.stateId === node.id);
        const cloned = cloneDeep(item);

        if (!cloned.stateClassId) {
            cloned.stateClassId = cloned.stateId;
        }
        setEditingNode(cloned);
    }, [value.states]);

    const onNodeContextMenu = useCallback((ev, node) => {
        ev.preventDefault();
        createMenuAnchor(ev);
        const item = value.states.find((item) => item.stateId === node.id);
        setOpeningNode(item);
    }, [createMenuAnchor, value.states]);

    const onNodeMenuClose = useCallback(() => {
        document.body.removeChild(menuAnchor);
        setMenuAnchor(null);
        setOpeningNode(null);
    }, [menuAnchor]);

    const onEditNode = useCallback(() => {
        const cloned = cloneDeep(openingNode);

        if (!cloned.stateClassId) {
            cloned.stateClassId = cloned.stateId;
        }
        setEditingNode(cloned);
        onNodeMenuClose();
    }, [onNodeMenuClose, openingNode]);

    const onNodeSubmit = useCallback((data) => {
        const next = cloneDeep(value);
        const index = next.states.findIndex((item) => item.stateId === data.stateId);
        next.states.splice(index, 1, data);
        setNodes((nds) => next.states.map(makeNode));
        onChange(next);
        setEditingNode(null);
    }, [value, editingNode]);

    const onDeleteNode = useCallback(() => {
        const next = cloneDeep(value);
        next.states = next.states.filter((item) => item.stateId !== openingNode.stateId);
        next.transitions = next.transitions.filter((item) => item.from.stateId !== openingNode.stateId);
        onChange(next);
        setNodes((nds) => nds.filter((item) => item.stateId !== openingNode.stateId));
        setOpeningNode(null);
        setMenuAnchor(null);
    }, [value, onChange, openingNode]);

    const handleConnect = useCallback(
        ({ source, target }) => {
            const from = value.states.find((item) => item.stateId === source);
            const to = value.states.find((item) => item.stateId === target);
            const transitionIndex = value.transitions.findIndex((item) => item.from.stateId === source);
            const existedTransition = value.transitions[transitionIndex];
            const transition = existedTransition ? cloneDeep(existedTransition) : {
                from: {
                    stateId: from.stateId,
                    taskGroupKey: from.taskGroupKey,
                },
                conditionIds: [],
                outputs: [
                ],
            };
            transition.outputs.push({
                expectedConditionResults: [],
                to: {
                    stateId: to.stateId,
                },
            });
            const next = cloneDeep(value);
            if (existedTransition) {
                next.transitions.splice(transitionIndex, 1, transition);
            } else {
                next.transitions.push(transition);
            }
            setEdges((eds) => makeEdges(next.transitions, robotConditions));
            
            onChange(next);
        },
        [value, onChange, setEdges, robotConditions],
    );

    const handleEdgeChange = useCallback((eds) => {
        onEdgesChange(eds);
    }, [onEdgesChange]);

    const handleEdgesDelete = useCallback((eds) => {
        const next = cloneDeep(value);
        eds.forEach((edge) => {
            const index = next.transitions.findIndex((item) => item.from.stateId === edge.source);
            const transition = next.transitions[index];
            transition.outputs = transition.outputs.filter((o) => o.to.stateId !== edge.target);
            if (!transition.outputs.length) {
                next.transitions.splice(index, 1);
            }
        });
        onChange(next);
    }, [value, onChange]);

    const onEdgeDoubleClick = useCallback((ev, edge) => {
        const transition = value.transitions.find((item) => {
            const sameTo = item.outputs.find((o) => o.to.stateId === edge.target);
            return item.from.stateId === edge.source && Boolean(sameTo);
        });
        const outputIndex = transition.outputs.findIndex((o) => o.to.stateId === edge.target);
        setEditingEdge([transition, outputIndex]);
    }, [value]);

    const onEdgeSubmit = useCallback((data) => {
        const [transition, outputIndex] = editingEdge;
        const index = value.transitions.findIndex((item) => item === transition);
        const next = cloneDeep(value);
        next.transitions[index].outputs.splice(outputIndex, 1, data);
        onChange(next);
        // const edge = makeEdge(data);
        setEdges(makeEdges(next.transitions, robotConditions));
        setEditingEdge(null);
    }, [value, editingEdge, robotConditions]);

    const onEdgeContextMenu = useCallback((ev, edge) => {
        ev.preventDefault();
        const transition = value.transitions.find((item) => item.from.stateId === edge.source && item.outputs.find((o) => o.to.stateId === edge.target));
        const outputIndex = transition.outputs.findIndex((o) => o.to.stateId === edge.target);
        if (outputIndex < 0) {
            return;
        }
        createMenuAnchor(ev);
        setOpeningTransition([transition, outputIndex]);
    }, [value.transitions]);

    const onTransitionMenuClose = useCallback(() => {
        document.body.removeChild(menuAnchor);
        setMenuAnchor(null);
        setOpeningTransition(null);
    }, [menuAnchor]);

    const onEditTransition = useCallback(() => {
        setEditingEdge(openingTransition);
        onTransitionMenuClose();
    }, [openingTransition, onTransitionMenuClose]);

    const onDeleteTransition = useCallback(() => {
        const next = cloneDeep(value);
        const index = value.transitions.findIndex((item) => item === openingTransition[0]);
        next.transitions[index].outputs.splice(openingTransition[1], 1);
        if (!next.transitions[index].outputs.length) {
            next.transitions.splice(index, 1);
        }
        setEdges((eds) => eds.filter((item) => item.source !== openingTransition[0].from.stateId || item.target !== openingTransition[0].outputs[openingTransition[1]].to.stateId));
        onChange(next);
        onTransitionMenuClose();
    }, [value, onChange, openingTransition, setEdges, onTransitionMenuClose]);

    const events = disabled ? {} : {
        onNodesChange: handleNodesChange,
        onNodeDoubleClick,
        onNodeContextMenu,
        onConnect: handleConnect,
        onEdgesDelete: handleEdgesDelete,
        onEdgesChange: handleEdgeChange,
        onEdgeDoubleClick,
        onEdgeContextMenu,
    };

    return (
        <>
            <ReactFlow
                fitView
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                {...events}
            >
                <Controls showZoom={false} showInteractive={false} position="top-left">
                    {disabled ? null : (
                        <ControlButton onClick={onAddNode}>Add</ControlButton>
                    )}
                </Controls>
                <Background />
            </ReactFlow>
            <Dialog open={Boolean(newNode)} maxWidth="md" fullWidth onClose={() => setNewNode(null)}>
                <DialogTitle>Add new node</DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm onSubmit={onNodeAdded}>
                            <StateClassSelect />
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
            <Dialog open={Boolean(editingNode)} maxWidth="md" fullWidth onClose={() => setEditingNode(null)}>
                <DialogTitle>Edit node</DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm onSubmit={onNodeSubmit} defaultValues={editingNode}>
                            <StateClassSelect />
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
            <Dialog open={Boolean(editingEdge)} maxWidth="md" fullWidth onClose={() => setEditingEdge(null)}>
                <DialogTitle>Edit output</DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm onSubmit={onEdgeSubmit} defaultValues={cloneDeep(editingEdge?.[0].outputs[editingEdge[1]])}>
                            <ValueProvider value={{ editingEdge }}>
                                <ArrayInput source="expectedConditionResults">
                                    <SimpleFormIterator inline>
                                        <ConditionSelect source="conditionId" />
                                        <ConditionResultSelect source="expectedResult" />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </ValueProvider>
                            <TaskGroupKeySelect source="transition.taskGroupKey" />
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
            <Menu
                open={Boolean(openingNode)}
                anchorEl={menuAnchor}
                onClose={onNodeMenuClose}
            >
                <MenuItem onClick={onEditNode}>Edit</MenuItem>
                <MenuItem onClick={onDeleteNode}>Delete</MenuItem>
            </Menu>
            <Menu
                open={Boolean(openingTransition)}
                anchorEl={menuAnchor}
                onClose={onTransitionMenuClose}
            >
                <MenuItem onClick={onEditTransition}>Edit</MenuItem>
                <MenuItem onClick={onDeleteTransition}>Delete</MenuItem>
            </Menu>
        </>
    );
}
